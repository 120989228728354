var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('h3',{staticClass:"float-left"},[_vm._v("Crear Atributo")])]),_c('CCardBody',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"contact-form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registrar)}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Atributo: ")]),_c('validation-provider',{attrs:{"name":"Atributo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.atributo),expression:"registro.atributo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.atributo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "atributo", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Orden: ")]),_c('validation-provider',{attrs:{"name":"Orden","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.orden),expression:"registro.orden"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.orden)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "orden", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Grupo: ")]),_c('validation-provider',{attrs:{"name":"Grupo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.grupo),expression:"registro.grupo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.grupo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "grupo", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Tipo: ")]),_c('validation-provider',{attrs:{"name":"Tipo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.tipo),expression:"registro.tipo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.tipo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "tipo", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Textura: ")]),_c('validation-provider',{attrs:{"name":"Textura","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.textura),expression:"registro.textura"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.textura)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "textura", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Color: ")]),_c('validation-provider',{attrs:{"name":"Color","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.color),expression:"registro.color"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "color", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Idioma: ")]),_c('validation-provider',{attrs:{"name":"Idioma","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.idioma),expression:"registro.idioma"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.idioma)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "idioma", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('span',{staticClass:"cil-save btn-icon mr-2"}),_vm._v(" Guardar ")]),_c('router-link',{attrs:{"to":"/ecoAtributos/List"}},[_c('CButton',{staticClass:"ml-2",attrs:{"color":"secondary"}},[_c('span',{staticClass:"cil-x-circle btn-icon mr-2"}),_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }